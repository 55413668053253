<template>
  <b-container fluid>
    <b-row class="heading_alignment">
      <b-col lg="12">
        <iq-card style="padding-left:20px; padding-right:20px" class="p-3">
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}
              <span class="btn_in_header">
                  <b-button variant="primary" @click="goBack()" class="ml-3 float-right">Go Back</b-button>
              </span>
            </h4>
          </template>
          <template v-slot:body>
            <div class="form-row">
              <div class="col-md-12 mb-3" v-if="vmSPCMFireFliesFormData.meeting_id">
                <label for="validationmeeting_id"><b>{{cvMeetingIdLabel}}</b></label>:&nbsp;
                <span v-html="vmSPCMFireFliesFormData.meeting_id"></span>
              </div>
              <div class="col-md-12 mb-3" v-if="vmSPCMFireFliesFormData.counselor_name">
                <label for="validationcounselor_id"><b>{{cvCounselorIdLabel}}</b></label>:&nbsp;
                <span v-html="vmSPCMFireFliesFormData.counselor_name"></span>
              </div>
              <div class="col-md-12 mb-3" v-if="vmSPCMFireFliesFormData.meeting_attendees">
                <label for="validationmeeting_attendees"><b>{{cvMeetingAttendeesLabel}}</b></label>
                  <p><span v-for="(attendee,index) of getStudentEmailIds(vmSPCMFireFliesFormData.meeting_attendees)" :key="index">
                    {{attendee}}
                    <i class="fa-solid fa-copy pointer ml-1" title="Copy Email Id" @click="doCopy(attendee)"></i><br>
                  </span></p>
              </div>

              <div class="col-md-12 mb-3" v-if="vmSPCMFireFliesFormData.title">
                <label for="validationtitle"><b>{{cvTitleLabel}}</b></label>:&nbsp;
                <span v-html="vmSPCMFireFliesFormData.title"></span>
              </div>
              <div class="col-md-12 mb-3" v-if="vmSPCMFireFliesFormData.summary">
                <p v-html="replaceNewLine(vmSPCMFireFliesFormData.summary)"></p>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { SPCMFireFlies } from "../../../FackApi/api/SPCMFireflies"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "SPCMFireFliesView",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propSPCMFireFliesObj: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "View Fireflies Meeting Info",
      cvMeetingIdLabel: "Meeting Id",
      cvCounselorIdLabel: "Counselor",
      cvTitleLabel: "Title",
      cvKeywordsLabel: "Keywords",
      cvMeetingAttendeesLabel: "Meeting Attendees",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "FireFlies Updated",
      vmSPCMFireFliesFormData: {}
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.spcmFireFliesView()
  },
  methods: {
    /**
     * go back
     */
    goBack () {
      this.$router.back()
    },
    /**
     * spcmFireFliesView
     */
    async spcmFireFliesView () {
      try {
        if (this.propOpenedInModal) {
          this.vmSPCMFireFliesFormData = this.propSPCMFireFliesObj
        }
        else {
          let SPCMFireFliesId = this.$route.params.spcmff_id
          let SPCMFireFliesViewResp = await SPCMFireFlies.spcmFireFliesView(this, SPCMFireFliesId)
          if (SPCMFireFliesViewResp && SPCMFireFliesViewResp.resp_status) {
            this.vmSPCMFireFliesFormData = SPCMFireFliesViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmFireFliesView() and Exception:", err.message)
      }
    },
    /**
     * Meedting Attendees
    */
    getStudentEmailIds (meetingAttendees) {
      let meetingAttendeesList = JSON.parse(meetingAttendees)
      let StudentList = []
      for (let attendee of meetingAttendeesList) {
        if (!attendee.email.includes("gide.ai") && !attendee.email.includes("highschoolmoms")) {
          StudentList.push(attendee.email)
        }
      }
      return StudentList
    },
    /**
     * Replace New Line
     */
    replaceNewLine (data) {
      return data.replace(/\\n/g, "<br>").replace(/"/g, "")
    }
  }
}
</script>
