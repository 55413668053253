/*eslint-disable */
// import SPCMFireFliesModel from '../../Model/SPCMFireFlies'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let SPCMFireFlies = {
  /**
   * SPCMFireFliesList
   */
  async SPCMFireFliesList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "spcm_fireflies_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcm_fireflies_list() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * spcmFireFliesView
   */
  async spcmFireFliesView (context, SPCMFireFliesId) {
    try {      
      let post_data = new FormData();
      post_data.append('spcmff_id', SPCMFireFliesId);
      return await request.curl(context, "spcm_fireflies_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at spcm_fireflies_view() and Exception:",err.message)
    }    
  },

  /**
   * SPCMFireFliesAdd
   */
  async SPCMFireFliesAdd (context, spcm_firefliesObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcm_firefliesObj) {
        post_data.append(key, spcm_firefliesObj[key]);
      }

      return await request.curl(context, "spcm_fireflies_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcm_fireflies_add() and Exception:',err.message)
    }
  },

  /**
   * SPCMFireFliesEdit
   */
  async SPCMFireFliesEdit (context, spcm_firefliesObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcm_firefliesObj) {
        post_data.append(key, spcm_firefliesObj[key]);
      }

      return await request.curl(context, "spcm_fireflies_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcm_fireflies_edit() and Exception:',err.message)
    }
  },

  /**
   * SPCMFireFliesDelete
   */
  async SPCMFireFliesDelete (context, SPCMFireFliesId) {
    try{
      let post_data = new FormData();
      
      post_data.append('spcmff_id', SPCMFireFliesId);

      return await request.curl(context, "spcm_fireflies_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at spcm_fireflies_delete() and Exception:',err.message)
    }
  },

  /**
   * SPCMFireFliesCounselorId
  */
  async SPCMFireFliesCounselorId (context) {
    try{
      let post_data = new FormData();  

      return await request.curl(context, "spcm_fireflies_counselor_id", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcm_fireflies_counselor_id() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * SPCMFireFliesSessionCreate
   * 
   */
  async SPCMFireFliesSessionCreate (context, SPCMFireFliesInviteObj) {
    try{
      let post_data = new FormData();
    
      for (let key in SPCMFireFliesInviteObj) {
        post_data.append(key, SPCMFireFliesInviteObj[key]);
      }

      return await request.curl(context, "spcm_fireflies_session_create", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcm_fireflies_session_create() and Exception:',err.message)
    }
  }
}

export {
  SPCMFireFlies
}
